import React from 'react';
import { graphql } from 'gatsby';
import PostHolder from '../components/blog-page/post-holder/PostHolder';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import Layout from '../components/global/layout/Layout';
import { CONSTANTS } from '../../utils/constants';
import { Title } from '../components/blog-page/styles';
import TopBar from '../components/blog-page/top-bar/TopBar';

const Authors = ({ data }) => {
	const posts = data.allContentfulBlogPost.nodes;
	return (
		<>
			<Layout>
				<WrapperOne>
					<Title>Blog</Title>
					<TopBar content="Everything" />
					{posts.length !== 0 && (
						<PostHolder
							search={`${CONSTANTS.POSTS_FROM} ${data.allContentfulBlogPost.nodes[0].author}`}
							data={posts}
							latest
						/>
					)}
				</WrapperOne>
			</Layout>
		</>
	);
};

export const query = graphql`
	query($slug: String) {
		allContentfulBlogPost(
			filter: { authorBio: { slug: { eq: $slug } } }
			sort: { order: DESC, fields: createdAt }
		) {
			nodes {
				slug
				timeToRead
				title
				category
				coverImage {
					fluid {
						...GatsbyContentfulFluid
					}
					title
				}
				author
			}
		}
	}
`;

export default Authors;
